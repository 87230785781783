import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-action-heading-block',
	templateUrl: './action-heading-block.component.html',
	styleUrls: ['./action-heading-block.component.scss']
})
export class ActionHeadingBlockComponent implements OnInit {
	@Input() heading: string;
	@Input() text: string;
	@Input() buttonText = 'View all';
	@Input() route: string;

	constructor() {}

	ngOnInit(): void {}
}
