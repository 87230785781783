import { Injectable, OnDestroy } from '@angular/core';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

// TODO: Add Angular decorator.
@Injectable({
	providedIn: 'root'
})
export class RouteLoadingService implements OnDestroy {
	subscription: Subscription;
	navigationEnd: Subject<boolean> = new Subject();
	// Sets initial value to true to show loading spinner on first load
	loading = false;

	constructor(router: Router) {
		this.subscription = router.events.pipe(tap((event: RouterEvent) => this.navigationInterceptor(event))).subscribe();
	}

	// Shows and hides the loading spinner during RouterEvent changes
	navigationInterceptor(event: RouterEvent): void {
		if (event instanceof NavigationStart) {
			this.loading = true;
		}
		if (event instanceof NavigationEnd) {
			this.loading = false;
			this.navigationEnd.next(true);
		}

		// Set loading state to false in both of the below events to hide the spinner in case a request fails
		if (event instanceof NavigationCancel) {
			this.loading = false;
			this.navigationEnd.next(true);
		}
		if (event instanceof NavigationError) {
			this.loading = false;
			this.navigationEnd.next(true);
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
