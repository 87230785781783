<div class="flex-row flex-center">
	<div>
		<h2>{{ heading }}</h2>
		<div class="lead">{{ text }}</div>
	</div>
	<div class="space-between"></div>
	<div class="flex-center">
		<a app-outline-button [routerLink]="route">{{ buttonText }}</a>
	</div>
</div>
