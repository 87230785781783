import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsonLdComponent } from './json-ld.component';

@NgModule({
	declarations: [JsonLdComponent],
	imports: [CommonModule],
	exports: [JsonLdComponent]
})
export class JsonLdModule {}
