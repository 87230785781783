import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { PerformanceMonitoringService } from '@angular/fire/compat/performance';
import { REGION } from '@angular/fire/compat/functions';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { environment } from '@seqmayors/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		BrowserTransferStateModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule,
		AngularFireAnalyticsModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		CoreModule
	],
	providers: [UserTrackingService, ScreenTrackingService, PerformanceMonitoringService, { provide: REGION, useValue: 'australia-southeast1' }],
	bootstrap: [AppComponent]
})
export class AppModule {}
