import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CtaRowBlockComponent } from './cta-row-block.component';

import { IconModule } from '@comseq/components/icon';
import { ButtonModule } from '@comseq/components/button';

@NgModule({
	declarations: [CtaRowBlockComponent],
	imports: [CommonModule, RouterModule, IconModule, ButtonModule],
	exports: [CtaRowBlockComponent]
})
export class CtaRowBlockModule {}
