import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';

import { MobileMenuComponent } from './mobile-menu.component';

import { LogoTextBlockModule } from '@comseq/components/blocks';
import { ButtonModule } from '@comseq/components/button';
import { ToolbarModule } from '@comseq/components/toolbar';
import { IconModule } from '@comseq/components/icon';

@NgModule({
	declarations: [MobileMenuComponent],
	imports: [CommonModule, ButtonModule, LogoTextBlockModule, OverlayModule, ToolbarModule, IconModule, RouterModule],
	exports: [MobileMenuComponent],
	entryComponents: [MobileMenuComponent]
})
export class MobileMenuModule {}
