import { trigger, state, style, animate, transition, AnimationTriggerMetadata } from '@angular/animations';

export const mobileMenuAnnimations: {
	readonly slideInOut: AnimationTriggerMetadata;
	readonly grow: AnimationTriggerMetadata;
} = {
	slideInOut: trigger('mobileMenuState', [
		state(
			'leave',
			style({
				transform: 'translate3d(-100%, 0, 0)',
				minWidth: '100%'
			})
		),
		state(
			'void',
			style({
				transform: 'translate3d(-100%, 0, 0)',
				minWidth: '100%'
			})
		),
		state(
			'enter',
			style({
				minWidth: '100%',
				transform: 'translate3d(0, 0, 0)'
			})
		),
		transition('void <=> enter', animate('120ms')),
		transition('leave <=> enter', animate('120ms'))
	]),

	grow: trigger('mobileMenuState', [
		state(
			'leave',
			style({
				transform: 'scale(.95)',
				opacity: 0
			})
		),
		state(
			'void',
			style({
				transform: 'scale(.95)',
				opacity: 0
			})
		),
		state(
			'enter',
			style({
				transform: 'scale(1)',
				opacity: 1
			})
		),
		transition('void <=> enter', animate('120ms')),
		transition('leave <=> enter', animate('120ms'))
	])
};

export const slideInOut = mobileMenuAnnimations.slideInOut;
export const grow = mobileMenuAnnimations.grow;
