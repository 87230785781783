import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { PAGES } from '../data/pages.model';
import { PageData } from '../models/page.model';

@Injectable({ providedIn: 'root' })
export class PageService {
	getPagePropertiesByRoute(route: string): PageData {
		const path = route.slice(1);
		return this.getPages().find(page => page.id === path);
	}

	getPages(): PageData[] {
		return Object.values(PAGES).filter(page => page.active === true);
	}

	getTopMenuNav(options?: { mobileNav: boolean }): Observable<PageData[]> {
		if (options.mobileNav) {
			return of(this.getPages().filter(page => page));
		} else {
			return of(this.getPages().filter(page => page.displaySettings && page.displaySettings.showInTopMenu === true));
		}
	}
}
