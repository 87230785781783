import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlyRenderOnServerDirective } from './only-on-server.directive';
import { onlyRenderOnBrowserDirective } from './only-on-browser.directive';

@NgModule({
	declarations: [OnlyRenderOnServerDirective, onlyRenderOnBrowserDirective],
	imports: [CommonModule],
	exports: [OnlyRenderOnServerDirective, onlyRenderOnBrowserDirective]
})
export class PlatformRenderModule {}
