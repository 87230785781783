<div class="svg-socials row">
	<a href="https://twitter.com/SEQMayors" target="_blank" class="black-300 hover-blue" rel="noopener" rel="noreferrer">
		<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 18 18" fill="currentColor">
			<path
				fill-rule="evenodd"
				d="M6.02 15.01c6.042 0 9.345-5.007 9.345-9.345 0-.144 0-.287-.007-.423A6.729 6.729 0 0 0 17 3.54a6.666 6.666 0 0 1-1.887.518 3.283 3.283 0 0 0 1.444-1.819 6.651 6.651 0 0 1-2.084.797 3.285 3.285 0 0 0-5.592 2.997A9.318 9.318 0 0 1 2.11 2.6a3.295 3.295 0 0 0-.443 1.649 3.27 3.27 0 0 0 1.464 2.731 3.226 3.226 0 0 1-1.485-.409v.041a3.288 3.288 0 0 0 2.636 3.222 3.274 3.274 0 0 1-1.485.055 3.28 3.28 0 0 0 3.065 2.281A6.602 6.602 0 0 1 1 13.525a9.15 9.15 0 0 0 5.02 1.485"
			></path>
		</svg>
	</a>
	<a href="https://www.instagram.com/seqfoodtrails/" target="_blank" rel="noopener" rel="noreferrer">
		<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 36 36">
			<g fill-rule="evenodd">
				<path
					d="M18 0c-4.889 0-5.501.02-7.421.108C8.663.196 7.354.5 6.209.945a8.823 8.823 0 0 0-3.188 2.076A8.83 8.83 0 0 0 .945 6.209C.5 7.354.195 8.663.108 10.58.021 12.499 0 13.11 0 18s.02 5.501.108 7.421c.088 1.916.392 3.225.837 4.37a8.823 8.823 0 0 0 2.076 3.188c1 1 2.005 1.616 3.188 2.076 1.145.445 2.454.75 4.37.837 1.92.087 2.532.108 7.421.108s5.501-.02 7.421-.108c1.916-.088 3.225-.392 4.37-.837a8.824 8.824 0 0 0 3.188-2.076c1-1 1.616-2.005 2.076-3.188.445-1.145.75-2.454.837-4.37.087-1.92.108-2.532.108-7.421s-.02-5.501-.108-7.421c-.088-1.916-.392-3.225-.837-4.37a8.824 8.824 0 0 0-2.076-3.188A8.83 8.83 0 0 0 29.791.945C28.646.5 27.337.195 25.42.108 23.501.021 22.89 0 18 0zm0 3.243c4.806 0 5.376.019 7.274.105 1.755.08 2.708.373 3.342.62.84.326 1.44.717 2.07 1.346.63.63 1.02 1.23 1.346 2.07.247.634.54 1.587.62 3.342.086 1.898.105 2.468.105 7.274s-.019 5.376-.105 7.274c-.08 1.755-.373 2.708-.62 3.342a5.576 5.576 0 0 1-1.346 2.07c-.63.63-1.23 1.02-2.07 1.346-.634.247-1.587.54-3.342.62-1.898.086-2.467.105-7.274.105s-5.376-.019-7.274-.105c-1.755-.08-2.708-.373-3.342-.62a5.576 5.576 0 0 1-2.07-1.346 5.577 5.577 0 0 1-1.346-2.07c-.247-.634-.54-1.587-.62-3.342-.086-1.898-.105-2.468-.105-7.274s.019-5.376.105-7.274c.08-1.755.373-2.708.62-3.342.326-.84.717-1.44 1.346-2.07.63-.63 1.23-1.02 2.07-1.346.634-.247 1.587-.54 3.342-.62 1.898-.086 2.468-.105 7.274-.105z"
				/>
				<path
					d="M18 24.006a6.006 6.006 0 1 1 0-12.012 6.006 6.006 0 0 1 0 12.012zm0-15.258a9.252 9.252 0 1 0 0 18.504 9.252 9.252 0 0 0 0-18.504zm11.944-.168a2.187 2.187 0 1 1-4.374 0 2.187 2.187 0 0 1 4.374 0"
				/>
			</g>
		</svg>
	</a>
	<a href="https://au.linkedin.com/company/council-of-mayors-seq-" target="_blank" rel="noopener" rel="noreferrer">
		<svg
			fill-rule="evenodd"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			width="30"
			height="30"
			viewBox="0 0 24 24"
		>
			<path
				d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
			/>
		</svg>
	</a>
</div>
