import { globalEnvironment } from 'environments/environment';

export const environment = {
	global: globalEnvironment,
	application: {
		project: 'SEQ Mayors'
	},
	firebase: {
		...globalEnvironment.firebase,
		appId: '1:500726760627:web:16a1b7875d571ea6d49cc6',
		measurementId: 'G-FE0W5SNNEY'
	},
	useEmulators: true,
	production: false
};

/* import 'zone.js/plugins/zone-error'; */
