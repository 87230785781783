import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileImageComponent } from './profile-image.component';

import { ImageModule } from '@comseq/components/utilities';

@NgModule({
	declarations: [ProfileImageComponent],
	imports: [CommonModule, ImageModule],
	exports: [ProfileImageComponent]
})
export class ProfileImageModule {}
