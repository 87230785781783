import { Component, Input, OnInit } from '@angular/core';

export interface FooterNavGroup {
	groupName: string;
	content: FooterNavItem[];
}

export interface FooterNavItem {
	text: string;
	type: 'internal' | 'external';
	url: string;
}

export const DEFAULT_FOOTER_ITEMS: FooterNavGroup[] = [
	{
		groupName: 'Useful links',
		content: [
			{
				text: 'SEQ Invest',
				type: 'external',
				url: 'https://seqinvest.com.au/'
			},
			{
				text: 'SEQ Food Trails',
				type: 'external',
				url: 'https://seqfoodtrails.com.au/'
			},
			{
				text: 'Resilient Rivers',
				type: 'external',
				url: 'https://resilientrivers.com.au/'
			}
		]
	},
	{
		groupName: 'Our Organisation',
		content: [
			{
				text: 'About us',
				type: 'internal',
				url: '/about-us'
			},
			{
				text: 'Board',
				type: 'internal',
				url: '/about-us/board'
			},
			{
				text: 'Newsletter',
				type: 'internal',
				url: '/contact/newsletter'
			}
		]
	},
	{
		groupName: 'Information',
		content: [
			{
				text: 'Privacy',
				type: 'internal',
				url: '/privacy'
			},
			{
				text: 'Contact us',
				type: 'internal',
				url: '/contact'
			}
		]
	},
	{
		groupName: 'Resources',
		content: [
			{
				text: 'Documents',
				type: 'internal',
				url: '/documents'
			},
			{
				text: 'Initiatives',
				type: 'internal',
				url: '/initiatives'
			}
		]
	}
];

@Component({
	selector: 'app-layout-footer',
	templateUrl: './layout-footer.component.html',
	styleUrls: ['./layout-footer.component.scss']
})
export class LayoutFooterComponent implements OnInit {
	@Input() navItems: FooterNavGroup[] = DEFAULT_FOOTER_ITEMS;
	currentYear: number = new Date().getFullYear();
	constructor() {}

	ngOnInit(): void {}
}
