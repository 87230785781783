<section class="section bg-grey">
    <div class="container">
        <h3 *ngIf="breakpointService.isHandset | async; else desktopVersion">
            {{ text }}
            <a class="mobile-link" [routerLink]="button.url">{{ button.text }}</a>
        </h3>
    </div>
</section>

<ng-template #desktopVersion>
    <div class="container flex flex-center cta-desktop">
        <div class="icon" *ngIf="icon">
            <app-icon>{{ icon }}</app-icon>
        </div>
        <div class="text">
            <h3>
                {{ text }}
            </h3>
        </div>
        <div>
            <a app-theme-button [routerLink]="button.url">{{ button.text | uppercase }}</a>
        </div>
    </div>
</ng-template>