import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-icon-list-block',
	templateUrl: './icon-list-block.component.html',
	styleUrls: ['./icon-list-block.component.scss']
})
export class IconListBlockComponent implements OnInit {
	@Input() icon: string = 'place';

	constructor() {}

	ngOnInit(): void {}
}
