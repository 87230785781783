import { Component, OnInit, EventEmitter, Input, AfterViewInit, ViewChild, ElementRef, ChangeDetectionStrategy, Output } from '@angular/core';
import { OperatorFunction, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, filter } from 'rxjs/operators';

export type SearchInputStyles = 'flat' | 'raised' | 'round';

@Component({
	selector: 'app-search-input',
	templateUrl: './search-input.component.html',
	styleUrls: ['./search-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class SearchInputComponent implements OnInit, AfterViewInit {
	@ViewChild('searchInput', { static: true }) private searchInput?: ElementRef;

	// SETTINGS
	@Input() focus: boolean = true;
	@Input() placeholder = 'Search';
	@Input() buttonIcon = 'search';
	@Input() buttonPersist: boolean = false;
	@Input() showAlogilaLogo?: boolean;

	_value: string;
	get value(): string {
		return this._value;
	}
	@Input() set value(value: string) {
		this._value = value;
		this.input = value;
		this.searchTerm.next(this.value);
	}

	searchTerm = new Subject<string>();

	// STYLES
	@Input() background?: string;
	@Input() theme?: SearchInputStyles = 'flat';

	@Output() onChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() onReset: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() onButtonClick: EventEmitter<boolean> = new EventEmitter<boolean>();

	subscriptions: Subscription;

	constructor() {
		this.subscriptions = new Subscription();
	}

	ngOnInit(): void {
		this.subscriptions.add(
			this.searchTerm
				.pipe(
					distinctUntilChanged(),
					debounceTime(200),
					tap(value => {
						this.onChange.emit(value);
					})
				)
				.subscribe()
		);
	}

	get input(): string {
		return this.searchInput.nativeElement.value;
	}

	set input(value: string) {
		this.searchInput.nativeElement.value = value;
	}

	toggleReset(): void {
		this.searchTerm.next('');
		this.input = '';
		this.onReset.emit(true);
	}

	onActionButtonClick() {
		this.onButtonClick.emit(true);
	}

	ngAfterViewInit(): void {
		if (this.focus) {
			this.searchInput.nativeElement.focus();
		}
	}
}

function filterEmpty<T>(): OperatorFunction<T | null | undefined, T> {
	return filter<T>(value => value !== undefined && value !== null);
}
