import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInOutBlockComponent } from './sign-in-out-block.component';
import { ProfileImageModule } from '@comseq/components/profile-image';
import { ButtonModule } from '@comseq/components/button';
import { IconModule } from '@comseq/components/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [SignInOutBlockComponent],
	imports: [CommonModule, ProfileImageModule, ButtonModule, IconModule, MatMenuModule, RouterModule],
	exports: [SignInOutBlockComponent]
})
export class SignInOutBlockModule {}
