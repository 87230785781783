import { Directive, ViewContainerRef, TemplateRef, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Directive({
	selector: '[onlyRenderOnServer]'
})
export class OnlyRenderOnServerDirective implements OnInit {
	constructor(@Inject(PLATFORM_ID) private platformId, private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>) {}

	ngOnInit() {
		if (isPlatformServer(this.platformId)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
