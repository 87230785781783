<div class="logo-container">
	<figure class="figure" title="Council of Mayors (SEQ)">
		<svg class="logo logo-item" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 158.15 158.15">
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_2-2" data-name="Layer 2">
					<circle class="cls-1" cx="79.07" cy="79.07" r="79.07" />
				</g>
				<g id="logo">
					<path class="cls-2" d="M74.25,61.88a9.46,9.46,0,1,1-9.72-9.19h.27A9.35,9.35,0,0,1,74.25,61.88Z" />
					<path class="cls-2" d="M74.25,82.07a9.46,9.46,0,1,1-9.72-9.19h.27A9.34,9.34,0,0,1,74.25,82.07Z" />
					<path class="cls-2" d="M74.25,102.2A9.46,9.46,0,1,1,64.53,93h.27A9.35,9.35,0,0,1,74.25,102.2Z" />
					<path class="cls-2" d="M74.25,122.41a9.46,9.46,0,1,1-9.72-9.19h.27A9.34,9.34,0,0,1,74.25,122.41Z" />
					<path class="cls-2" d="M94.93,82.07a9.46,9.46,0,1,1-9.72-9.19h.27A9.32,9.32,0,0,1,94.93,82.07Z" />
					<path class="cls-2" d="M94.93,102.2A9.46,9.46,0,1,1,85.21,93h.27A9.33,9.33,0,0,1,94.93,102.2Z" />
					<path class="cls-3" d="M94.93,122.41a9.46,9.46,0,1,1-9.72-9.19h.27A9.32,9.32,0,0,1,94.93,122.41Z" />
					<path class="cls-3" d="M115.59,102.2A9.46,9.46,0,1,1,105.87,93h.27A9.33,9.33,0,0,1,115.59,102.2Z" />
					<path class="cls-3" d="M115.59,122.41a9.46,9.46,0,1,1-9.72-9.19h.27A9.32,9.32,0,0,1,115.59,122.41Z" />
					<path class="cls-3" d="M134.34,113a9.46,9.46,0,1,1-9.72-9.19h.27a9.32,9.32,0,0,1,9.45,9.19Z" />
					<path class="cls-2" d="M53.34,82.07a9.46,9.46,0,1,1-9.72-9.19h.26A9.34,9.34,0,0,1,53.34,82.07Z" />
					<path class="cls-2" d="M53.34,102.2A9.46,9.46,0,1,1,43.62,93h.26A9.35,9.35,0,0,1,53.34,102.2Z" />
					<path class="cls-2" d="M51.13,61.88a7.14,7.14,0,1,1-7.33-6.94H44A7,7,0,0,1,51.13,61.88Z" />
					<path class="cls-2" d="M92.61,61.88a7.13,7.13,0,1,1-7.33-6.94h.2A7,7,0,0,1,92.61,61.88Z" />
					<path class="cls-2" d="M72.05,41.5a7.13,7.13,0,1,1-7.34-6.93h.21A7,7,0,0,1,72.05,41.5Z" />
					<path class="cls-2" d="M72.05,21.14a7.13,7.13,0,1,1-7.33-6.93h.2A7,7,0,0,1,72.05,21.14Z" />
					<path class="cls-2" d="M113.4,82.07a7.14,7.14,0,1,1-7.33-6.93h.19a7,7,0,0,1,7.14,6.93Z" />
				</g>
			</g>
		</svg>
	</figure>
	<span class="logo-item app-name">{{ text }}</span>
</div>
