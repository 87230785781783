import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-social-media-links',
	templateUrl: './social-media-links.component.html',
	styleUrls: ['./social-media-links.component.scss']
})
export class SocialMediaLinksComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
