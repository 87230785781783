<div class="mobile-menu-content" tabindex="-1">
	<app-toolbar class="mobile-toolbar">
		<a class="logo" routerLink="/" (click)="closeMenu()">
			<app-logo-text-block></app-logo-text-block>
		</a>
		<span class="space-between"></span>
		<button app-icon-button (click)="closeMenu()">
			<app-icon>close</app-icon>
		</button>
	</app-toolbar>

	<ul class="nav-section first-section">
		<li role="menuitem" *ngFor="let navItem of navItems | async | slice: 0:5">
			<a class="nav-link" [routerLink]="'/' + navItem.id" routerLinkActive="active" (click)="closeMenu()">
				<span class="nav-icon">
					<app-icon>{{ navItem.icon }}</app-icon>
				</span>
				<span>{{ navItem.title }}</span>
			</a>
		</li>
	</ul>
	<div class="nav-section second-section">
		<ul>
			<li role="menuitem" *ngFor="let navItem of secondaryNavItems">
				<a class="nav-link" [routerLink]="'/' + navItem.id" routerLinkActive="active" (click)="closeMenu()">
					<span>{{ navItem.title }}</span>
				</a>
			</li>
		</ul>
	</div>
	<a class="nav-footer" routerLink="/contact" (click)="closeMenu()"> Contact us </a>
</div>
