import { Component, OnInit, Directive } from '@angular/core';

@Component({
	selector: 'app-icon-block',
	templateUrl: './icon-block.component.html',
	styleUrls: ['./icon-block.component.scss']
})
export class IconBlockComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}

@Directive({
	selector: 'app-icon-block-icon',
	exportAs: '[appIconBlockIcon]'
})
export class IconBlockIconDirective implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}

@Directive({
	selector: 'app-icon-block-header',
	exportAs: '[iconBlockHeader]'
})
export class IconBlockHeaderDirective implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
