import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, HostListener, HostBinding } from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import { Observable, Subject } from 'rxjs';

import { grow } from './mobile-menu-animations';

import { MobileOverlayRef } from '@seqmayors/app/core/layout/components/mobile-menu/mobile-overlay-ref';
import { PageService } from '@seqmayors/app/core/services/page.service';
import { PageData } from '@seqmayors/app/core/models/page.model';

declare type AnimationState = 'void' | 'enter' | 'leave';

const ESCAPE = 27;

@Component({
	selector: 'app-mobile-menu',
	templateUrl: './mobile-menu.component.html',
	styleUrls: ['./mobile-menu.component.scss'],
	animations: [grow],
	changeDetection: ChangeDetectionStrategy.Default
})
export class MobileMenuComponent implements OnInit {
	navItems: Observable<PageData[]>;
	secondaryNavItems = [
		{
			id: 'about-us/south-east-queensland',
			title: 'South East Queensland (SEQ)'
		},
		{
			id: 'about-us/board',
			title: 'Our Board'
		}
	];
	animationState: AnimationState = 'enter';
	animationDone = new Subject<AnimationEvent>();
	animationStateChanged = new EventEmitter<AnimationEvent>();
	@HostBinding('class.mobile-menu-panel') mobileMenuClass = true;
	@HostBinding('@mobileMenuState') get state(): string {
		return this.animationState;
	}
	@HostListener('@mobileMenuState.start', ['$event']) onAnimationStart(event: AnimationEvent): void {
		this.animationStateChanged.emit(event);
	}
	@HostListener('@mobileMenuState.done', ['$event']) onAnimationDone(event: AnimationEvent): void {
		this.animationStateChanged.emit(event);
	}

	@HostListener('document:keydown', ['$event']) private handleKeydown(event: KeyboardEvent): void {
		if (event.keyCode === ESCAPE) {
			this.mobileOverlayRef.close();
		}
	}

	constructor(public mobileOverlayRef: MobileOverlayRef, private pageService: PageService) {
		this.navItems = this.pageService.getTopMenuNav({ mobileNav: false });
	}

	ngOnInit(): void {}

	startExitAnimation(): void {
		this.animationState = 'leave';
	}

	closeMenu(): void {
		this.mobileOverlayRef.close();
	}
}
