import { RequestOptions } from '@algolia/transporter';
import { SearchOptions } from '@algolia/client-search';

export type AssetType = 'article' | 'board member' | 'initiative' | 'priority' | 'council' | 'document';

export const DEFAULT_ATTRIBUTES: string[] = ['name', 'topic', 'desc', 'dateCreated', 'type', 'image', 'route'];

export type AlgoliaRequestOptions = RequestOptions & SearchOptions;

export class Search {
	query: string;
	requestOptions?: AlgoliaRequestOptions;
	constructor(data: Search) {
		this.query = data.query;
		this.requestOptions = data.requestOptions;
	}
}

export interface Results<T> {
	exhaustiveNbHits: boolean;
	facets: any;
	hits: Array<T>;
	hitsPerPage: number;
	nbHits: number;
	nbPages: number;
	page: number;
	params: string;
	processingTimeMS: number;
	query: string;
}

export interface ISearchItem {
	name: string;
	desc: string;
	topic: string;
	tags: Array<string> | null;
	image?: string;
	text?: string;
	dateCreated: Date;
	ranking?: number;
	type: AssetType;
	status: string;
	route: string;
}

export class SearchItem implements ISearchItem {
	name: string;
	desc: string;
	topic: string;
	tags: Array<string> | null;
	image?: string;
	text?: string;
	dateCreated: Date;
	ranking?: number;
	type: AssetType;
	status: string;
	route: string;
	constructor(data: ISearchItem) {
		this.name = data.name;
		this.desc = data.desc;
		this.topic = data.topic;
		this.tags = data.tags;
		this.image = data.image;
		this.text = data.text;
		this.dateCreated = data.dateCreated;
		this.ranking = data.ranking;
		this.type = data.type;
		this.status = data.status;
		this.route = data.route;
	}
}
