<footer>
	<div class="footer-section section-mp">
		<div class="container">
			<div class="flex-row flex-wrap">
				<nav class="col-6 col-md-6 col-lg-3" *ngFor="let group of navItems">
					<h4 class="group-name">{{ group.groupName }}</h4>
					<ul class="items">
						<li *ngFor="let item of group.content">
							<span [ngSwitch]="item.type">
								<ng-container *ngSwitchCase="'external'">
									<a [href]="item.url" rel="noopener" target="_blank">
										<span>{{ item.text }}</span>
										<span>
											<app-icon class="external-link-icon"> open_in_new </app-icon>
										</span>
									</a>
								</ng-container>
								<ng-container *ngSwitchCase="'internal'">
									<a [routerLink]="item.url">{{ item.text }}</a>
								</ng-container>
							</span>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</div>

	<div class="footer-section section-sp">
		<div class="container">
			<div class="flex-row bottom-row">
				<div class="col-6">
					<div>Copyright © {{ currentYear }} Council of Mayors (SEQ) | All Rights Reserved</div>
					<div>ABN: 87 141 329 302</div>
				</div>
				<div class="col-6 text-right">
					<app-social-media-links></app-social-media-links>
				</div>
			</div>
		</div>
	</div>
</footer>
