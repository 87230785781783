import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LayoutService {
	private _searchOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public searchOpen$: Observable<boolean> = this._searchOpen.asObservable();

	constructor() {}

	public set searchOpen(state: boolean) {
		this._searchOpen.next(state);
	}

	public get searchOpen(): boolean {
		return this._searchOpen.value;
	}
}
