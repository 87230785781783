import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SignInBlockComponent } from './sign-in-block.component';

import { SpinnerModule } from '@comseq/components/spinner';
import { IconModule } from '@comseq/components/icon';

import { MatFormFieldModule } from '@angular/material/form-field';
import { ButtonModule } from '@comseq/components/button';
import { MatInputModule } from '@angular/material/input';
import { LogoBlockModule } from '../logo-block';

@NgModule({
	declarations: [SignInBlockComponent],
	imports: [
		CommonModule,
		SpinnerModule,
		IconModule,
		ReactiveFormsModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		ButtonModule,
		LogoBlockModule
	],
	exports: [SignInBlockComponent]
})
export class SignInBlockModule {}
