import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ButtonModule } from '@comseq/components/button';
import { ActionHeadingBlockComponent } from './action-heading-block.component';

@NgModule({
	declarations: [ActionHeadingBlockComponent],
	imports: [CommonModule, ButtonModule, RouterModule],
	exports: [ActionHeadingBlockComponent]
})
export class ActionHeadingBlockModule {}
