import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImageComponent } from './image.component';
import { DeferLoadModule } from '../defer-load';

@NgModule({
	declarations: [ImageComponent],
	imports: [CommonModule, DeferLoadModule],
	exports: [ImageComponent]
})
export class ImageModule {}
