<header *ngIf="headerTemplate">
	<nav>
		<ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
	</nav>
</header>

<main>
	<ng-content></ng-content>
</main>

<ng-container *ngTemplateOutlet="footerTemplate ? footerTemplate : defaultFooter"></ng-container>

<ng-template #defaultHeader>DEFAULT HEADER</ng-template>

<ng-template #defaultFooter>
	<app-layout-footer></app-layout-footer>
</ng-template>
