<div class="container">
	<div class="soft-elevation">
		<h2 class="mb-0 mt-10">Sign in</h2>
		<div class="text-muted mb-10">Sign in to your account here.</div>
		<div class="content">
			<button class="full-width" app-theme-button *ngIf="msSignIn" (click)="onMsSignIn()">
				<span class="flex flex-center mr-10">
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
						<title>MS-SymbolLockup</title>
						<rect x="1" y="1" width="9" height="9" fill="#f25022" />
						<rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
						<rect x="11" y="1" width="9" height="9" fill="#7fba00" />
						<rect x="11" y="11" width="9" height="9" fill="#ffb900" />
					</svg>
				</span>
				<span class="space-between"></span>
				<span>SIGN IN WITH MICROSOFT</span>
			</button>

			<div class="divider" *ngIf="msSignIn"></div>

			<h4 *ngIf="msSignIn">Or use your email</h4>

			<form class="form-container" [formGroup]="signInForm" *ngIf="!isAuthenticating; else authLoadingTemplate">
				<div class="flex flex-row flex-center">
					<app-icon class="mr-10">person_outline</app-icon>
					<mat-form-field>
						<input matInput type="email" placeholder="Email" formControlName="email" autocomplete="off" required />
						<mat-error *ngIf="signInForm.controls['email'].hasError('email') && !signInForm.controls['email'].hasError('required')">
							Please enter a valid email address
						</mat-error>
						<mat-error *ngIf="signInForm.controls['email'].hasError('required')"> Email is <strong>required</strong> </mat-error>
					</mat-form-field>
				</div>
				<div class="flex flex-row flex-center">
					<app-icon class="mr-10">vpn_key</app-icon>
					<mat-form-field>
						<input
							matInput
							type="password"
							placeholder="Password"
							formControlName="password"
							autocomplete="current-password"
							[type]="hide ? 'password' : 'text'"
							required
						/>
						<app-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</app-icon>
						<mat-error *ngIf="signInForm.controls['password'].hasError('required')"> Password is <strong>required</strong> </mat-error>
					</mat-form-field>
				</div>

				<div class="sign-in-btn flex margin-top flex-center-all">
					<button class="full-width" app-theme-button (click)="signIn()" [disabled]="!signInForm.valid">Sign in</button>
				</div>
			</form>
		</div>
	</div>

	<div class="mt-10">
		<a href="https://seqmayors.qld.gov.au/contact" target="_blank" rel="noopener noreferrer">Don't have an account yet? Contact us</a>
	</div>
</div>

<ng-template #authLoadingTemplate>
	<app-spinner *ngIf="isAuthenticating"></app-spinner>
</ng-template>
