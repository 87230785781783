import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{ path: '', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
	{ path: 'about-us', loadChildren: () => import('./modules/about-us/about-us.module').then(m => m.AboutUsModule) },
	{ path: 'news', loadChildren: () => import('./modules/articles/articles.module').then(m => m.ArticlesModule) },
	{ path: 'events', loadChildren: () => import('./modules/events/events.module').then(m => m.EventsModule) },
	{ path: 'initiatives', loadChildren: () => import('./modules/initiatives/initiatives.module').then(m => m.InitiativesModule) },
	/* {
		path: 'map',
		data: {
			layout: {
				header: false,
				footer: false,
				fullScreen: true
			}
		},
		loadChildren: () => import('./modules/map/map.module').then(m => m.MapModule)
	}, */
	{ path: 'documents', loadChildren: () => import('./modules/documents/documents.module').then(m => m.DocumentsModule) },
	{ path: 'contact', loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule) },
	{ path: 'privacy', loadChildren: () => import('./modules/legal/legal.module').then(m => m.LegalModule) },
	{ path: 'olympics', loadChildren: () => import('./modules/olympics/olympics.module').then(m => m.OlympicsModule) },
	{ path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
			scrollPositionRestoration: 'top',
			anchorScrolling: 'enabled',
			relativeLinkResolution: 'corrected'
		})
	],
	providers: [],
	exports: [RouterModule]
})
export class AppRoutingModule {}
