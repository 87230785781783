import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';

import { RouteLoadingService } from '@comseq/components/core/services';

export type ToolbarState = 'soft-elevation' | 'hard-elevation';

@Component({
	selector: 'app-layout-header',
	templateUrl: './layout-header.component.html',
	styleUrls: ['./layout-header.component.scss']
})
export class LayoutHeaderComponent implements OnInit {
	/** Adds a full-screen class to the toolbar when the input is set to fullScreen */
	@Input() fullScreen: boolean = false;

	/** Applies a hard elevation when the user scrolls from 0 scrollTop */
	@HostBinding('class') shadow: string = 'soft-elevation';
	@HostListener('window:scroll') onWindowScroll() {
		if (this.shadow) {
			const shadowType = document.documentElement.scrollTop > 0 ? 'hard-elevation' : 'soft-elevation';
			this.shadow = shadowType;
		}
	}

	constructor(public routeLoadingService: RouteLoadingService) {}

	ngOnInit(): void {}
}
