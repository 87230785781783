import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { Observable, of } from 'rxjs';

import { StorageService } from '@comseq/components/core/services';
import { fadeInAnimation } from './image-animations';

@Component({
	selector: 'app-image',
	templateUrl: './image.component.html',
	styleUrls: ['./image.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [fadeInAnimation]
})
export class ImageComponent implements OnInit, OnChanges {
	@Input() public src?: string;
	@Input() public storagePath?: string;
	display = false;
	imageState = 'hidden';

	constructor(private readonly storageService: StorageService) {}

	ngOnInit(): void {
		this.imageState = 'hidden';
	}

	ngOnChanges(): void {
		this.imageState = 'hidden';
	}

	animateImage(): void {
		this.imageState = 'visable';
	}

	displayImage(): void {
		this.display = true;
	}

	getImage(): Observable<string | undefined> {
		return this.storagePath ? this.getImageFromStorage(this.storagePath) : of(this.src);
	}

	getImageFromStorage(storagePath: string): Observable<string | undefined> {
		return this.storageService.getDownloadUrl(storagePath);
	}
}
