import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInputComponent } from './search-input.component';

import { IconModule } from '@comseq/components/icon';
import { ButtonModule } from '@comseq/components/button';

@NgModule({
	declarations: [SearchInputComponent],
	imports: [CommonModule, IconModule, ButtonModule],
	exports: [SearchInputComponent]
})
export class SearchInputModule {}
