import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconListBlockComponent } from './icon-list-block.component';
import { IconModule } from '@comseq/components/icon';

@NgModule({
	declarations: [IconListBlockComponent],
	imports: [CommonModule, IconModule],
	exports: [IconListBlockComponent]
})
export class IconListBlockModule {}
