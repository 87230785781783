<app-search-input
	cdkOverlayOrigin
	#originOverlay="cdkOverlayOrigin"
	#appSearch
	(onReset)="detach()"
	(onChange)="onChange($event)"
	buttonIcon="search"
	placeholder="Find a project, article or resource"
	[showAlogilaLogo]="false"
>
</app-search-input>

<ng-template
	cdkConnectedOverlay
	[cdkConnectedOverlayOrigin]="originOverlay"
	[cdkConnectedOverlayOpen]="true"
	[cdkConnectedOverlayOffsetX]="-16"
	[cdkConnectedOverlayOffsetY]="7"
	cdkConnectedOverlayHasBackdrop
	[cdkConnectedOverlayScrollStrategy]="scrollStrategy"
	(backdropClick)="detach()"
	cdkConnectedOverlayBackdropClass="search-results-overlay"
	cdkConnectedOverlayPanelClass="search-results-panel"
	(detach)="detach()"
>
	<ng-container *ngTemplateOutlet="resultTemplate; context: { results: (results | async)?.hits }"></ng-container>
</ng-template>

<!-- RESULT TEMPLATE -->
<ng-template #resultTemplate let-results="results">
	<a *ngFor="let result of results; trackBy: trackByFn" class="result clickable" [routerLink]="result.route" (click)="detach(result.name)">
		<section class="results-detail">
			<div class="meta">
				<time>{{ result.dateCreated | date: 'MMM yyyy' }}</time> <span> | {{ result.topic | uppercase }} </span>
				<strong class="topic"> [{{ result.type | uppercase }}]</strong>
			</div>
			<h4 class="result-title">
				{{ result.name }}
			</h4>
		</section>
		<section [ngSwitch]="result.type">
			<div *ngSwitchCase="'document'"><app-icon class="doc-icon">description</app-icon></div>
			<div *ngSwitchDefault><app-image [src]="result.image"></app-image></div>
		</section>
	</a>
</ng-template>

<!-- NO RESULTS TEMPLATE -->
<ng-template #noResultsTemplate>
	<div class="result">
		<span>Sorry, we couldn't find any content matching your query.</span>
	</div>
</ng-template>
