import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
	selector: '[deferLoad]'
})
export class DeferLoadDirective implements OnInit, AfterViewInit, OnDestroy {
	@Input() public preRender = true;
	@Input() public fallbackEnabled = true;
	@Output() public deferLoad: EventEmitter<any> = new EventEmitter();

	private intersectionObserver?: IntersectionObserver;
	private scrollSubscription?: Subscription;

	constructor(@Inject(PLATFORM_ID) private platformId: Object, private element: ElementRef, private zone: NgZone) {}

	public ngOnInit() {
		if ((isPlatformServer(this.platformId) && this.preRender === true) || (isPlatformBrowser(this.platformId) && this.fallbackEnabled === false && !this.hasCompatibleBrowser())) {
			this.load();
		}
	}

	public ngAfterViewInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			if (this.hasCompatibleBrowser()) {
				this.registerIntersectionObserver();
				if (this.intersectionObserver && this.element.nativeElement) {
					this.intersectionObserver.observe(this.element.nativeElement as Element);
				}
			} else if (this.fallbackEnabled === true) {
				this.addScrollListeners();
			}
		}
	}

	public hasCompatibleBrowser(): boolean {
		const hasIntersectionObserver = 'IntersectionObserver' in window;
		const userAgent = window.navigator.userAgent;
		const matches = userAgent.match(/Edge\/(\d*)\./i);

		const isEdge = !!matches && matches.length > 1;
		const isEdgeVersion16OrBetter = isEdge && !!matches && parseInt(matches[1], 10) > 15;

		return hasIntersectionObserver && (!isEdge || isEdgeVersion16OrBetter);
	}

	public ngOnDestroy(): void {
		this.removeListeners();
	}

	private registerIntersectionObserver(): void {
		if (!!this.intersectionObserver) {
			return;
		}
		this.intersectionObserver = new IntersectionObserver(entries => {
			this.checkForIntersection(entries);
		}, {});
	}

	private checkForIntersection = (entries: Array<IntersectionObserverEntry>) => {
		entries.forEach((entry: IntersectionObserverEntry) => {
			if (this.checkIfIntersecting(entry)) {
				this.load();
				if (this.intersectionObserver && this.element.nativeElement) {
					this.intersectionObserver.unobserve(this.element.nativeElement as Element);
				}
			}
		});
	};

	private checkIfIntersecting(entry: IntersectionObserverEntry): boolean {
		// For Samsung native browser, IO has been partially implemented where by the
		// callback fires, but entry object is empty. We will check manually.
		if (entry && entry.time) {
			return (entry as any).isIntersecting && entry.target === this.element.nativeElement;
		}
		return this.isVisible();
	}

	private load(): void {
		this.removeListeners();
		this.deferLoad.emit();
	}

	private addScrollListeners(): void {
		if (this.isVisible()) {
			this.load();
			return;
		}
		this.zone.runOutsideAngular(() => {
			this.scrollSubscription = fromEvent(window, 'scroll').pipe(debounceTime(50)).subscribe(this.onScroll);
		});
	}

	private removeListeners(): void {
		if (this.scrollSubscription) {
			this.scrollSubscription.unsubscribe();
		}

		if (this.intersectionObserver) {
			this.intersectionObserver.disconnect();
		}
	}

	private onScroll() {
		if (this.isVisible()) {
			this.zone.run(() => this.load());
		}
	}

	private isVisible(): boolean {
		const scrollPosition = this.getScrollPosition();
		const elementOffset = this.element.nativeElement.offsetTop;
		return elementOffset <= scrollPosition;
	}

	private getScrollPosition(): number {
		// Getting screen size and scroll position for IE
		return (window.scrollY || window.pageYOffset) + (document.documentElement.clientHeight || document.body.clientHeight);
	}
}
