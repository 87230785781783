import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

import { Observable, Subscription } from 'rxjs';

import { LayoutService } from '../services/layout.service';
import { PageService } from '../services/page.service';
import { OverlayService } from '../services/overlay.service';

import type { PageData } from '@comseq/components';
import { BreakpointService } from '@comseq/components/core/services';
import { filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

export class Layout {
	header: boolean;
	footer: boolean;
	fullScreen: boolean;
}

export const DEFAULT_LAYOUT: Layout = {
	header: true,
	footer: true,
	fullScreen: false
};

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class LayoutComponent implements OnDestroy {
	public headerNavItems: Observable<PageData[]>;

	private subscriptions: Subscription = new Subscription();

	layout: Layout = DEFAULT_LAYOUT;

	constructor(
		private readonly analytics: AngularFireAnalytics,
		private readonly pageService: PageService,
		private readonly overlayService: OverlayService,
		public readonly layoutService: LayoutService,
		public readonly breakpointService: BreakpointService,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {
		this.headerNavItems = this.pageService.getTopMenuNav({ mobileNav: false });
		this.subscriptions.add(
			this.router.events
				.pipe(
					filter(event => event instanceof NavigationEnd),
					switchMap(event => this.activatedRoute.firstChild.data),
					withLatestFrom(this.activatedRoute.data),
					tap(([firstChildData, routeData]) => {
						this.layout = { ...DEFAULT_LAYOUT, ...firstChildData.layout };
					})
				)
				.subscribe()
		);
	}

	public openMobileMenu(): void {
		this.overlayService.openOverlay();
	}

	public openSearch(): void {
		this.layoutService.searchOpen = true;
		this.analytics.logEvent('site_search', { toggle: 'open' });
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
