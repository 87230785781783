import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class BreakpointService {
	public isHandset?: Observable<boolean>;

	constructor(private breakpointObserver: BreakpointObserver) {
		this.setBreakpoint('1100px');
	}

	public setBreakpoint(value: string): void {
		this.isHandset = this.breakpointObserver.observe(`(max-width: ${value})`).pipe(map(result => result.matches));
	}
}
