import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-sign-in-block',
	templateUrl: './sign-in-block.component.html',
	styleUrls: ['./sign-in-block.component.scss']
})
export class SignInBlockComponent implements OnInit {
	@Input() isAuthenticating: boolean;
	@Input() msSignIn?: boolean = true;
	@Output() onSignIn = new EventEmitter<any>();
	@Output() onMsSignInClicked = new EventEmitter<any>();
	hide: boolean = true;
	signInForm: FormGroup;

	constructor(public fb: FormBuilder) {
		this.signInForm = fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required]]
		});
	}

	ngOnInit() {}

	signIn() {
		this.onSignIn.emit(this.signInForm.value);
	}

	onMsSignIn() {
		this.onMsSignInClicked.emit(true);
	}
}
