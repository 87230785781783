import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
	selector: 'app-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent implements OnInit {
	@HostBinding('class.app-progress-bar') class = true;
	@Input() color?: string;
	@Input() routeLoading?: boolean;

	constructor() {}

	ngOnInit(): void {}
}
