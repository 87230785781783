import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoBlockComponent } from './logo-block.component';

@NgModule({
	declarations: [LogoBlockComponent],
	imports: [CommonModule],
	exports: [LogoBlockComponent]
})
export class LogoBlockModule {}
