export const GLOBAL_SECRETS = {
	firebase: {
		apiKey: 'AIzaSyDLwR7yBeBtgT6Val47kJC7318HvCQ1a60',
		authDomain: 'council-of-mayors.firebaseapp.com',
		databaseURL: 'https://council-of-mayors.firebaseio.com',
		projectId: 'council-of-mayors',
		storageBucket: 'council-of-mayors.appspot.com',
		messagingSenderId: '500726760627'
	},
	algolia: {
		appId: 'DMHU1ID8GP',
		apiKey: '32386cbee36b4d11dda5088bc4510a2c'
	},
	googleMapsApi: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBtAdahVxQeXdonm_c3rnFrik6d_MzNmmU',
	mapbox: {
		accessToken: 'pk.eyJ1IjoiY29tc2VxIiwiYSI6ImNraHk3aG1kejBiM3MycHIwaG51bnN4cWMifQ.kIUuuTBSKaJWkiS5TE_z_A'
	}
};
