<app-layout-block [footerTemplate]="footerTemplate" [headerTemplate]="layout.header === true ? headerTemplate : null">
	<ng-content></ng-content>
</app-layout-block>

<ng-template #headerTemplate>
	<app-layout-header [fullScreen]="layout.fullScreen">
		<ng-container *ngIf="!(layoutService.searchOpen$ | async); else searchTemplate">
			<ng-container *ngTemplateOutlet="logoTemplate"></ng-container>
			<ng-container *ngTemplateOutlet="(breakpointService.isHandset | async) === true ? mobileHeaderTemplate : desktopHeaderTemplate"> </ng-container>
		</ng-container>
	</app-layout-header>
</ng-template>

<ng-template #footerTemplate>
	<app-layout-footer *ngIf="layout.footer"></app-layout-footer>
</ng-template>

<!-- MOBILE HEADER -->
<ng-template #mobileHeaderTemplate>
	<span class="space-between"></span>
	<ng-container *ngTemplateOutlet="searchButtonTemplate"></ng-container>
	<button class="ml-12" app-icon-button (click)="openMobileMenu()">
		<app-icon>menu</app-icon>
	</button>
</ng-template>

<!-- DESKTOP HEADER -->
<ng-template #desktopHeaderTemplate>
	<span class="space-between"></span>
	<app-header-nav-item *ngFor="let item of headerNavItems | async" [navItem]="item"></app-header-nav-item>
	<div class="nav-divider"></div>
	<ng-container *ngTemplateOutlet="searchButtonTemplate"></ng-container>
	<span class="ml-12">
		<a app-theme-button routerLink="/contact">CONTACT US</a>
	</span>
</ng-template>

<!-- SEARCH TOOLBAR -->
<ng-template #searchTemplate>
	<app-header-search></app-header-search>
</ng-template>

<!-- SEARCH BUTTON -->
<ng-template #searchButtonTemplate>
	<button app-icon-button (click)="openSearch()">
		<app-icon>search</app-icon>
	</button>
</ng-template>

<!-- APP LOGO -->
<ng-template #logoTemplate>
	<a class="logo" routerLink="/">
		<app-logo-text-block></app-logo-text-block>
	</a>
</ng-template>
