import { PageData, PageNames } from '../models/page.model';

// PAGES ROUTES / ID
const ABOUT = 'about-us';
const NEWS = 'news';
const INITIATIVES = 'initiatives';
const DOCUMENTS = 'documents';
const MAP = 'map';
const EVENTS = 'events';
const CONTACT = 'contact';
const PRIVACY = 'privacy';
const OLYMPICS = 'olympics';

export const PAGES: Record<PageNames, PageData> = {
	[ABOUT]: {
		id: ABOUT,
		title: 'About us',
		subtitle:
			'Our mission is to consistently deliver better regional funding, policy and collaborative outcomes for the communities of South East Queensland.',
		active: true,
		icon: 'info',
		displaySettings: {
			showInTopMenu: true
		}
	},
	[`${ABOUT}/board`]: {
		id: `${ABOUT}/board`,
		title: 'Our board',
		subtitle: 'Learn about our leadership team and the mayors of South East Queensland (SEQ).',
		active: true,
		icon: 'people',
		parentId: ABOUT
	},
	[`${ABOUT}/our-organisation`]: {
		id: `${ABOUT}/our-organisation`,
		title: 'Our organisation',
		subtitle: 'Learn about what we do, and find our latest annual report.',
		active: true,
		icon: 'article',
		parentId: ABOUT
	},
	[`${ABOUT}/south-east-queensland`]: {
		id: `${ABOUT}/south-east-queensland`,
		title: 'South East Queensland',
		subtitle: 'Learn about South East Queensland (SEQ) and what it has to offer.',
		active: true,
		icon: 'place',
		parentId: ABOUT
	},
	[`${ABOUT}/councils`]: {
		id: `${ABOUT}/councils`,
		title: 'SEQ Councils',
		subtitle: 'South East Queensland (SEQ) Councils working together.',
		active: true,
		icon: 'account_balance',
		parentId: ABOUT
	},
	[NEWS]: {
		id: NEWS,
		title: 'News',
		subtitle: 'Get the leatest news',
		active: true,
		icon: 'article',
		displaySettings: {
			showInTopMenu: true
		}
	},
	[INITIATIVES]: {
		id: INITIATIVES,
		title: 'Initiatives',
		subtitle: 'View all our Initiatives & Projects',
		active: true,
		icon: 'description',
		displaySettings: {
			showInTopMenu: true
		}
	},
	[DOCUMENTS]: {
		id: DOCUMENTS,
		title: 'Documents',
		subtitle:
			'We produce a wide range of publications for the public, government and industry, including reports, studies, and updates. Please use the search feautre to find more documents.',
		active: true,
		icon: 'description',
		displaySettings: {
			showInTopMenu: true
		}
	},
	[OLYMPICS]: {
		id: OLYMPICS,
		title: '2032 Games',
		subtitle:
			'Recognising that connectivity is the lifeblood of SEQ and its economy, an Olympic and Paralympic Games could accelerate the funding and delivery of major infrastructure and be a game-changer for the future of SEQ.',
		active: true,
		icon: 'description',
		displaySettings: {
			showInTopMenu: true
		}
	},
	[EVENTS]: {
		id: EVENTS,
		title: 'Events',
		subtitle: 'Join us for our various events, bringing together the best minds and ideas in South East Queensland (SEQ).',
		active: true,
		icon: 'event_note',
		displaySettings: {
			showInTopMenu: false,
			showChildren: false
		}
	},
	[MAP]: {
		id: MAP,
		title: 'Map',
		subtitle: 'Explore our interactive map of the region',
		active: false,
		icon: 'place',
		displaySettings: {
			showInTopMenu: true
		}
	},
	[CONTACT]: {
		id: CONTACT,
		title: 'Contact',
		subtitle: 'Get in touch with us',
		active: true,
		icon: 'textsms',
		displaySettings: {
			showChildren: false,
			showInTopMenu: false
		}
	},
	[`${CONTACT}/newsletter`]: {
		id: `${CONTACT}/newsletter`,
		title: 'Advocacy Alert',
		subtitle: 'Stay up-to-date',
		active: true,
		icon: 'mail'
	},
	[PRIVACY]: {
		id: PRIVACY,
		title: 'Our Privacy Policy',
		subtitle: 'We are committed to protecting personal privacy',
		active: true,
		icon: 'privacy_tip'
	}
};
