import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
	selector: 'app-layout-block',
	templateUrl: './layout-block.component.html',
	styleUrls: ['./layout-block.component.scss']
})
export class LayoutBlockComponent implements OnInit {
	@Input() headerTemplate?: TemplateRef<any>;
	@Input() footerTemplate?: TemplateRef<any>;
	constructor() {}

	ngOnInit(): void {}
}
