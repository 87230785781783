import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'app-header-nav-item',
	templateUrl: './nav-item.component.html',
	styleUrls: ['./nav-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderNavItemComponent {
	@Input() navItem: { id: string; title: string };

	constructor() {}
}
