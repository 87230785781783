import { Component, ChangeDetectionStrategy, ViewEncapsulation, ElementRef, Input, HostBinding } from '@angular/core';

const BUTTON_HOST_ATTRIBUTES = [
	'app-button',
	'app-flat-button',
	'app-icon-button',
	'app-outline-button',
	'app-theme-button'
];

const DEFAULT_COLOR = '#f6f8f9';

@Component({
	selector: 'button[app-theme-button], button[app-flat-button], button[app-icon-button], button[app-outline-button]',
	exportAs: 'appButton',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
	@HostBinding('class') classAttribute = 'button-wrapper';
	@HostBinding('style.background-color') @Input() backgroundColor: string;
	@HostBinding('style.color') @Input() fontColor: string;
	@Input() color?: string;

	readonly isIconButton: boolean = this.hasHostAttributes('app-icon-button');

	constructor(public _elementRef: ElementRef) {
		for (const attr of BUTTON_HOST_ATTRIBUTES) {
			if (this.hasHostAttributes(attr)) {
				(this.getHostElement() as HTMLElement).classList.add(attr);
			}
		}

		if (this.isIconButton && this.color === undefined) {
			this.backgroundColor = DEFAULT_COLOR;
		}
	}

	getHostElement() {
		return this._elementRef.nativeElement;
	}

	hasHostAttributes(...attributes: string[]) {
		return attributes.some(attribute => this.getHostElement().hasAttribute(attribute));
	}
}

@Component({
	selector: 'a[app-flat-button], a[app-icon-button], a[app-theme-button], a[app-outline-button]',
	exportAs: 'appButton',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnchorComponent extends ButtonComponent {
	@Input() disabled?: boolean;
	@Input() color?: string;

	_haltDisabledEvents(event: Event) {
		// A disabled button shouldn't apply any actions
		if (this.disabled) {
			event.preventDefault();
			event.stopImmediatePropagation();
		}
	}
}
