import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconBlockComponent, IconBlockIconDirective, IconBlockHeaderDirective } from './icon-block.component';

import { IconModule } from '@comseq/components/icon';

@NgModule({
	declarations: [IconBlockComponent, IconBlockIconDirective, IconBlockHeaderDirective],
	imports: [CommonModule, IconModule],
	exports: [IconBlockComponent, IconBlockIconDirective, IconBlockHeaderDirective]
})
export class IconBlockModule {}
