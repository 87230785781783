import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-profile-image',
	templateUrl: './profile-image.component.html',
	styles: [
		`
			:host {
				height: 48px;
				width: 48px;
			}
			app-image {
				border-radius: 50%;
				height: inherit;
				width: inherit;
				object-fit: inherit;
			}
		`
	]
})
export class ProfileImageComponent implements OnInit {
	defaultAvatar = '/images/base/profile-placeholder.svg';
	@Input('src') src?: string | null = this.defaultAvatar;

	constructor() {}

	ngOnInit(): void {
		this.src = this.src || this.defaultAvatar;
	}
}
