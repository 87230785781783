import { Injectable, Injector, ComponentRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { MobileMenuComponent } from '../layout/components/mobile-menu/mobile-menu.component';
import { MobileOverlayRef } from '../layout/components/mobile-menu/mobile-overlay-ref';

export interface AppOverlayConfig {
	panelClass?: string;
	hasBackdrop?: boolean;
	backdropClass?: string;
}

const DEFAULT_CONFIG = {
	hasBackdrop: true,
	backdropClass: 'cdk-overlay-dark-backdrop',
	panelClass: 'cdk-overlay-pane-mobile'
};

@Injectable({
	providedIn: 'root'
})
export class OverlayService {
	constructor(private injector: Injector, private overlay: Overlay) {}

	public openOverlay(config: AppOverlayConfig = {}) {
		const dialogConfig = { ...DEFAULT_CONFIG, ...config };
		const overlayRef = this.createOverlay(dialogConfig);
		const dialogRef = new MobileOverlayRef(overlayRef);
		const overlayComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);
		dialogRef.componentInstance = overlayComponent;
		overlayRef.backdropClick().subscribe(() => {
			dialogRef.close();
		});
		return dialogRef;
	}

	private createOverlay(config: AppOverlayConfig): OverlayRef {
		const overlayConfig = this.getOverlayConfig(config);
		return this.overlay.create(overlayConfig);
	}

	private attachDialogContainer(overlayRef: OverlayRef, config: AppOverlayConfig, dialogRef: MobileOverlayRef) {
		const injector = this.createInjector(config, dialogRef);
		const containerPortal = new ComponentPortal(MobileMenuComponent, null, injector);
		const containerRef: ComponentRef<MobileMenuComponent> = overlayRef.attach(containerPortal);
		return containerRef.instance;
	}

	private createInjector(config: AppOverlayConfig, dialogRef: MobileOverlayRef): PortalInjector {
		const injectionTokens = new WeakMap();
		injectionTokens.set(MobileOverlayRef, dialogRef);
		return new PortalInjector(this.injector, injectionTokens);
	}

	private getOverlayConfig(config: AppOverlayConfig): OverlayConfig {
		const positionStrategy = this.overlay.position().global();

		const overlayConfig = new OverlayConfig({
			hasBackdrop: config.hasBackdrop,
			backdropClass: config.backdropClass,
			panelClass: 'cdk-overlay-pane-mobile',
			scrollStrategy: this.overlay.scrollStrategies.block(),
			positionStrategy
		});

		return overlayConfig;
	}
}
