import { Component, ChangeDetectionStrategy, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { CdkConnectedOverlay, ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

import { EMPTY, Observable, of, Subject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { SearchItem, Results, Search } from '@seqmayors/app/core/classes/search-item.interface';
import { SearchService } from '@seqmayors/app/core/services/search.service';
import { LayoutService } from '@seqmayors/app/core/services/layout.service';

@Component({
	selector: 'app-header-search',
	templateUrl: './header-search.component.html',
	styleUrls: ['./header-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class HeaderSearchComponent implements OnInit {
	@ViewChild(CdkConnectedOverlay) connectedOverlay?: CdkConnectedOverlay;
	@Output() onClose: EventEmitter<boolean> = new EventEmitter();
	scrollStrategy: ScrollStrategy;
	searchQuery: Subject<string> = new Subject();
	results: Observable<Results<SearchItem>>;

	constructor(
		private searchService: SearchService,
		scrollStrategyOptions: ScrollStrategyOptions,
		private layoutService: LayoutService,
		private analytics: AngularFireAnalytics
	) {
		this.scrollStrategy = scrollStrategyOptions.block();
		this.results = this.getResults();
	}

	ngOnInit(): void {}

	/* getPopularResults(): Observable<Results<T>> {
		return this.searchQuery.pipe(
			filter(value => value !== null),
			switchMap(value => {
				const query = this.buildQuery(value);
				if (value) {
					return this.searchService.search<T>('prod_searchIndex', query);
				} else {
					return of(null);
				}
			})
		);
	} */

	getResults<T>(): Observable<Results<T>> {
		return this.searchQuery.pipe(
			filter(value => value !== null),
			switchMap(value => {
				const query = this.buildQuery(value);
				if (value) {
					return this.searchService.search<T>('prod_searchIndex', query);
				} else {
					return of(null);
				}
			})
		);
	}

	private buildQuery(value: string): Search {
		return new Search({
			query: value,
			requestOptions: {
				hitsPerPage: 20
			}
		});
	}

	public onChange($event: string): void {
		this.searchQuery.next($event);
	}

	public detach(name?: string): void {
		this.layoutService.searchOpen = false;
		this.logSearch(name);
	}

	public trackByFn(index: any, item: any): void {
		return index;
	}

	public logSearch(name: string) {
		this.analytics.logEvent('site_search', { clicked: name });
	}
}
