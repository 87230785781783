import { OverlayRef } from '@angular/cdk/overlay';
import { Injectable, OnDestroy } from '@angular/core';
import { MobileMenuComponent } from './mobile-menu.component';
import { filter, take } from 'rxjs/operators';
import { Subject, Observable, Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MobileOverlayRef implements OnDestroy {
	private _beforeClose = new Subject<void>();
	private _afterClosed = new Subject<void>();
	componentInstance?: MobileMenuComponent;
	subscriptions: Subscription = new Subscription();

	constructor(private overlayRef: OverlayRef) {}

	public close(): void {
		if (this.componentInstance) {
			this.subscriptions.add(
				this.componentInstance.animationStateChanged
					.pipe(
						filter(event => event.phaseName === 'start'),
						take(1)
					)
					.subscribe(x => {
						this._beforeClose.next();
						this._beforeClose.complete();
						this.overlayRef.detachBackdrop();
					})
			);

			this.subscriptions.add(
				this.componentInstance.animationStateChanged
					.pipe(
						filter(event => event.phaseName === 'done' && event.toState === 'leave'),
						take(1)
					)
					.subscribe(x => {
						this.overlayRef.dispose();
						this._afterClosed.next();
						this._afterClosed.complete();
						this.componentInstance = null!;
					})
			);
			this.componentInstance.startExitAnimation();
		}
	}

	public afterClosed(): Observable<void> {
		return this._afterClosed.asObservable();
	}

	public beforeClose(): Observable<void> {
		return this._beforeClose.asObservable();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
