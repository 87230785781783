import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import type { User } from '@comseq/components';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-sign-in-out-block',
	templateUrl: './sign-in-out-block.component.html',
	styleUrls: ['./sign-in-out-block.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInOutBlockComponent implements OnInit {
	@Input() user: Observable<User | null>;
	@Output() onSignIn = new EventEmitter<boolean>();
	@Output() onSignOut = new EventEmitter<boolean>();
	@Output() onProfileClicked = new EventEmitter<boolean>();

	constructor() {}

	ngOnInit(): void {}

	public signInClicked(): void {
		this.onSignIn.emit(true);
	}

	public profileClicked(): void {
		this.onProfileClicked.emit(false);
	}

	public onSignOutClicked(): void {
		this.onSignOut.emit(true);
	}
}
