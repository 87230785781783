import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoTextBlockComponent } from './logo-text-block.component';
import { LogoBlockModule } from '../logo-block';

@NgModule({
	declarations: [LogoTextBlockComponent],
	imports: [CommonModule, LogoBlockModule],
	exports: [LogoTextBlockComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LogoTextBlockModule {}
