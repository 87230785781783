import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';

import { HeaderSearchComponent } from './header-search.component';

import { ImageModule } from '@comseq/components/utilities';
import { SearchInputModule } from '@comseq/components/form-fields/search-input';
import { IconModule } from '@comseq/components/icon';

@NgModule({
	declarations: [HeaderSearchComponent],
	imports: [CommonModule, OverlayModule, RouterModule, ImageModule, SearchInputModule, IconModule],
	exports: [HeaderSearchComponent]
})
export class HeaderSearchModule {}
