import { Injectable } from '@angular/core';
import { environment } from '@seqmayors/environments/environment';
import algoliasearch, { SearchIndex } from 'algoliasearch';
import { from, Observable } from 'rxjs';
import { Search, Results } from '../classes/search-item.interface';

@Injectable({
	providedIn: 'root'
})
export class SearchService {
	DEFAULT_ATTRIBUTES: string[] = ['name', 'topic', 'dateCreated', 'type', 'image', 'route'];
	client = algoliasearch(environment.global.algolia.appId, environment.global.algolia.apiKey);

	constructor() {}

	search<T>(index: string, search: Search): Observable<Results<T>> {
		const currentIndex: SearchIndex = this.client.initIndex(index);
		const promise = currentIndex.search(search.query, search.requestOptions).then(results => {
			return (results as unknown) as Results<T>;
		});
		return from(promise);
	}
}
