<!-- User logged in -->
<div *ngIf="user | async as user; else guest" class="flex flex-row flex-center">
	<button app-flat-button [matMenuTriggerFor]="menu">
		<span>{{ user.name }}</span>
		<app-icon class="ml-10">keyboard_arrow_down</app-icon>
	</button>
	<mat-menu #menu="matMenu">
		<button mat-menu-item [routerLink]="['/dashboard']">Dashboard</button>
		<button mat-menu-item (click)="onSignOutClicked()">Sign out</button>
	</mat-menu>
</div>

<!-- User NOT logged in -->
<ng-template #guest>
	<button app-outline-button (click)="signInClicked()">Sign in</button>
</ng-template>
