import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LayoutComponent } from './layout.component';
import { MobileMenuModule } from './components/mobile-menu/mobile-menu.module';
import { HeaderSearchModule } from './components/header-search/header-search.module';

import { LayoutBlockModule, LogoTextBlockModule } from '@comseq/components/blocks';
import { IconModule } from '@comseq/components/icon';
import { ButtonModule } from '@comseq/components/button';

@NgModule({
	declarations: [LayoutComponent],
	imports: [CommonModule, RouterModule, LayoutBlockModule, IconModule, LogoTextBlockModule, MobileMenuModule, HeaderSearchModule, ButtonModule],
	exports: [LayoutComponent],
	providers: []
})
export class LayoutModule {}
