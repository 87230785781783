import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { BreakpointService } from '@comseq/components/core/services';

@Component({
	selector: 'app-cta-row-block',
	templateUrl: './cta-row-block.component.html',
	styleUrls: ['./cta-row-block.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtaRowBlockComponent implements OnInit {
	constructor(public readonly breakpointService: BreakpointService) {}
	@Input() button?: {
		text: string;
		url: string;
	};
	@Input() icon?: string;
	@Input() text?: string;
	ngOnInit(): void {}
}
