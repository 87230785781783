import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-logo-text-block',
	templateUrl: './logo-text-block.component.html',
	styleUrls: ['./logo-text-block.component.scss']
})
export class LogoTextBlockComponent implements OnInit {
	@Input() text = 'Council of Mayors (SEQ)';
	constructor() {}

	ngOnInit(): void {}
}
