import { Component, ContentChild, Input, OnInit, ViewChild } from '@angular/core';

@Component({
	selector: 'app-logo-block',
	templateUrl: './logo-block.component.svg',
	styleUrls: ['./logo-block.component.scss']
})
export class LogoBlockComponent implements OnInit {
	@Input() height?: string;
	@Input() width?: string;

	constructor() {}

	ngOnInit(): void {}
}
