import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	constructor(private storage: AngularFireStorage, private state: TransferState) {}

	getDownloadUrl(storageRef: string): Observable<string> {
		const ref = this.storage.ref(storageRef);
		const key = makeStateKey(storageRef);
		const existing = this.state.get<unknown>(key, undefined);
		const getDownloadUrl = existing
			? of(existing)
			: ref.getDownloadURL().pipe(
					tap(url => this.state.set(key, url)),
					take(1)
			  );
		return getDownloadUrl as Observable<string>;
	}
}
