import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LayoutBlockComponent } from './layout-block.component';
import { LayoutHeaderComponent } from './components/layout-header/layout-header.component';
import { LayoutFooterComponent } from './components/layout-footer/layout-footer.component';
import { SocialMediaLinksComponent } from './components/layout-footer/social-media-links/social-media-links.component';
import { HeaderNavItemComponent } from './components/layout-header/header-nav-item/nav-item.component';

import { IconModule } from '@comseq/components/icon';
import { ProgressBarModule } from '@comseq/components/progress-bar';
import { ToolbarModule } from '@comseq/components/toolbar';

@NgModule({
	declarations: [LayoutBlockComponent, LayoutHeaderComponent, LayoutFooterComponent, SocialMediaLinksComponent, HeaderNavItemComponent],
	imports: [CommonModule, ToolbarModule, IconModule, RouterModule, ProgressBarModule],
	exports: [LayoutBlockComponent, LayoutHeaderComponent, LayoutFooterComponent, HeaderNavItemComponent, SocialMediaLinksComponent]
})
export class LayoutBlockModule {}
