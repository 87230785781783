import { Directive, OnInit, ViewContainerRef, TemplateRef, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Directive({
	selector: '[onlyRenderOnBrowser]'
})
export class onlyRenderOnBrowserDirective implements OnInit {
	constructor(@Inject(PLATFORM_ID) private platformId, private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>) {}

	ngOnInit() {
		if (isPlatformServer(this.platformId)) {
			this.viewContainer.clear();
		} else {
			this.viewContainer.createEmbeddedView(this.templateRef);
		}
	}
}
