import { state, style, transition, animate, trigger } from '@angular/animations';

export const fadeInAnimation = [
	trigger('fadeAnimation', [
		state(
			'visable',
			style({
				opacity: 1
			})
		),
		state(
			'hidden',
			style({
				opacity: 0
			})
		),
		transition('visable => hidden', [animate('0s')]),
		transition('hidden => visable', [animate('0.5s')])
	])
];
